import React, { useEffect, useState } from 'react'
import {
    Box,
    Typography,
    TextField,
    Button,
    FormControl,
    InputAdornment,
    IconButton,
    Input,
} from '@mui/material'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { toast } from 'react-toastify';
import { useUpdateUserPasswordMutation } from '../../redux/api/Users/updateUserPassword';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../redux/features/loader';

const ChangePassword = ({ handleClose, handleLogOut }) => {
    const userId = localStorage.getItem("userId");
    const dispatch = useDispatch();
    const showToastMessage = (message, type) => {
        if (type === "success") {
          toast.success(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (type === "error") {
          toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (type === "info") {
          toast.warning(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      };

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false,
      });

    const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

   const handleOldPassword = (event)=>{
    setCurrentPassword(event.target.value);
    // clear title when field is not empty
    if (event.target.value !== "") {
        setErrors((prevErrors) => ({ ...prevErrors, currentPassword: false }));
      }
   } 
   const handleNewPassword = (event)=>{
    setNewPassword(event.target.value);
    // clear title when field is not empty
    if (event.target.value !== "") {
        setErrors((prevErrors) => ({ ...prevErrors, newPassword: false }));
      }
   }
   const handleConfirmNewPassword = (event)=>{
    setConfirmPassword(event.target.value);
    // clear title when field is not empty
    if (event.target.value !== "") {
        setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: false }));
      }
   }

   const [updatePassword, { isLoading, isError }] = useUpdateUserPasswordMutation() ;

   const handleUpdatePassword = async () => {
     let hasError = false;
     const newErrors = { currentPassword: false, newPassword: false, confirmPassword: false };
 
     if (currentPassword === "") {
       newErrors.currentPassword = true;
       hasError = true;
       document.querySelector("#currentPassword").focus();
     }
 
     if (newPassword === "") {
       newErrors.newPassword = true;
       hasError = true;
       document.querySelector("#newPassword").focus();
     }
 
     if (confirmPassword === "") {
       newErrors.confirmPassword = true;
       hasError = true;
       document.querySelector("#confirmPassword").focus();
     }

         // Check if new password matches confirm password
     if (newPassword !== confirmPassword) {
        showToastMessage("New password and confirm password do not match", "error");
        // hasError = true;
      }
  
      // Check if old password and new password are the same
      if (currentPassword&&newPassword && currentPassword === newPassword) {
        showToastMessage("Old password and new password cannot be the same", "error");
        // hasError = true;
      }
 
     if (hasError) {
       setErrors(newErrors);
       return;
     }
 
     try {
       const response = await updatePassword({
         userId:userId,
         currentPassword:currentPassword,
         newPassword:newPassword,
         confirmPassword:confirmPassword,
       }).unwrap();
       
       if (response.status === "success") {
         showToastMessage("password changed", "success");
         handleLogOut();
       }
       handleLogOut();
     } catch (error) {
        showToastMessage(error?.data?.message, "error");
       // Hide the loader when the API call is finished, regardless of the outcome
       dispatch(hideLoader());
     }
   };

//    useEffect(()=>{
//    if(isLoading){
//     dispatch(showLoader())
//    }else{
//     dispatch(showLoader())
//    }
//    }, [isLoading, dispatch])

    return (
        <>

            <Box
                sx={{
                    mb: 3
                }}
            >
                <Typography variant='h5'
                    sx={{
                        fontSize: "1.3rem",
                        color: "secondary.main",
                        fontWeight: "700"
                    }}>
                    Change Password
                </Typography>
            </Box>
            <Box
                sx={{
                    mb: 3,
                }}
            >
                <FormControl variant="filled"
                    sx={{
                        mb: 3,
                        width: '100%',
                        '& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
                            border: 'none',
                            background: 'transparent'
                        },
                        '& .MuiInputBase-root:before': {
                            border: 'none'
                        },
                        '& .MuiInputBase-root:after': {
                            border: 'none'
                        },
                    }}
                >
                    <Input
                        sx={{
                            width: '100%',
                            borderRadius: '4px',
                            backgroundColor: 'extraLTheme.main',
                            border: 'solid 1px #DBEBF8',
                            '& .MuiInputBase-input': {
                                fontSize: '0.9rem',
                                color: 'lightSecondary.main',
                                fontWeight: '500',
                                p: 1.5
                            },
                        }}
                        id="currentPassword"
                        type={showOldPassword ? 'text' : 'password'}
                        placeholder='Enter Old Password'
                        value={currentPassword}
                        onChange={handleOldPassword}
                        autoComplete="new-password" 
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowOldPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showOldPassword ? <VisibilityOffOutlinedIcon sx={{color: 'lightSecondary.main'}} /> : <VisibilityOutlinedIcon sx={{color: 'lightSecondary.main'}} />}
                                </IconButton>
                            </InputAdornment>
                        }
                        
                    />
                    {errors.currentPassword && (
                    <Typography
                        variant="body2"
                        color="error"
                        sx={{
                        display: "flex",
                        ml: "0px",
                        }}
                    >
                         Please enter old password
                    </Typography>
                    )}
                </FormControl>
                <FormControl variant="filled"
                    sx={{
                        mb: 3,
                        width: '100%',
                        '& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
                            border: 'none',
                            background: 'transparent'
                        },
                        '& .MuiInputBase-root:before': {
                            border: 'none'
                        },
                        '& .MuiInputBase-root:after': {
                            border: 'none'
                        },
                    }}
                >
                    <Input
                        sx={{
                            width: '100%',
                            borderRadius: '4px',
                            backgroundColor: 'extraLTheme.main',
                            border: 'solid 1px #DBEBF8',
                            '& .MuiInputBase-input': {
                                fontSize: '0.9rem',
                                color: 'lightSecondary.main',
                                fontWeight: '500',
                                p: 1.5
                            }
                        }}
                        id="newPassword"
                        type={showNewPassword ? 'text' : 'password'}
                        placeholder='Enter New Password'
                        value={newPassword}
                        onChange={handleNewPassword}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowNewPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showNewPassword ? <VisibilityOffOutlinedIcon sx={{color: 'lightSecondary.main'}} /> : <VisibilityOutlinedIcon sx={{color: 'lightSecondary.main'}} />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    {errors.newPassword && (
                    <Typography
                        variant="body2"
                        color="error"
                        sx={{
                        display: "flex",
                        ml: "0px",
                        }}
                    >
                         Please enter new password
                    </Typography>
                    )}
                </FormControl>
                <FormControl variant="filled"
                    sx={{
                        mb: 3,
                        width: '100%',
                        '& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
                            border: 'none',
                            background: 'transparent'
                        },
                        '& .MuiInputBase-root:before': {
                            border: 'none'
                        },
                        '& .MuiInputBase-root:after': {
                            border: 'none'
                        },
                    }}
                >
                    <Input
                        sx={{
                            width: '100%',
                            borderRadius: '4px',
                            backgroundColor: 'extraLTheme.main',
                            border: 'solid 1px #DBEBF8',
                            '& .MuiInputBase-input': {
                                fontSize: '0.9rem',
                                color: 'lightSecondary.main',
                                fontWeight: '500',
                                p: 1.5
                            },
                        }}
                        id="confirmPassword"
                        type={showConfirmPassword ? 'text' : 'password'}
                        placeholder='Re-enter New Password'
                        value={confirmPassword}
                        onChange={handleConfirmNewPassword}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showConfirmPassword ? <VisibilityOffOutlinedIcon sx={{color: 'lightSecondary.main'}} /> : <VisibilityOutlinedIcon sx={{color: 'lightSecondary.main'}} />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    {errors.confirmPassword && (
                    <Typography
                        variant="body2"
                        color="error"
                        sx={{
                        display: "flex",
                        ml: "0px",
                        }}
                    >
                        Please re-enter new password
                    </Typography>
                    )}
                </FormControl>
            </Box>
            <Box
                sx={{
                    mt: 4,
                    display: 'flex',
                    gap: 2,
                    px: 5
                }}
            >
                <Button variant="outlined"
                    sx={{
                        textTransform: 'inherit',
                        p: "10px 25px",
                        width: '50%',
                        borderWidth: '2px',
                        fontWeight: '600',
                        '&:hover': {
                            borderWidth: '2px',
                        }
                    }}
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button variant="contained"
                    sx={{
                        textTransform: 'inherit',
                        p: "12px 25px",
                        width: '50%',
                        boxShadow: 'none',
                        fontWeight: '600',
                        '&:hover': {
                            boxShadow: 'none',
                        }
                    }}
                    onClick={handleUpdatePassword}
                    >

                    Update
                </Button>
            </Box>
        </>
    )
}

export default ChangePassword